import React, { useEffect, useMemo, useState } from 'react'
import { useToggle } from 'react-use'
import { useTheme } from 'styled-components'
import { debounce } from 'lodash'
import { match } from 'ts-pattern'
import { amountToPriceString, Currency } from '@teamfeedr/utils--money'
import { validateBasketItemUpdate } from '@teamfeedr/utils--gm-validation'
import Stack from '@teamfeedr/ui--stack'
import Icon from '@teamfeedr/ui--icon'
import { calculateItemSubtotal } from '@teamfeedr/utils--basket'
import { TextExtraExtraSmall, TextExtraSmall, TextSmall } from '@teamfeedr/ui--typography'
import { BasketItemFragment, MenuItemOptionType } from '@/generated/graphql'
import { areFieldsNotNil } from '../../utils/typing'
import { Button, Card, IconButton, Stack as MuiStack, Typography } from '@mui/material'
import { CloseRounded, ExpandLessRounded, ExpandMoreRounded, Notes } from '@mui/icons-material'
import QuantitySelect from '@/components/QuantitySelect'

export type Props = {
  item: BasketItemFragment
  currency: Currency
  debounceQuantityChange?: boolean
  onQuantityChange?: (qty: number) => void
  onDeleteClick?: () => void
  showPricesExTax: boolean
  taxAcronym: string
}

const BasketItemCard: React.FC<Props> = ({
  item,
  currency,
  debounceQuantityChange = true,
  onQuantityChange,
  onDeleteClick,
  showPricesExTax,
  taxAcronym,
}) => {
  const theme = useTheme()
  const [quantity, setQuantity] = useState(item.qty)
  const [showOptions, toggleShowOptions] = useToggle(false)

  const triggerQuantityChangeWithDebounce = useMemo(
    () => debounce((value: number) => onQuantityChange && onQuantityChange(value), 500),
    [onQuantityChange],
  )

  const onChange = (value: number) => {
    if (!onQuantityChange) return

    if (debounceQuantityChange) {
      setQuantity(value)
      triggerQuantityChangeWithDebounce(value)
    } else {
      onQuantityChange(value)
    }
  }
  const options = item.options
    .filter(areFieldsNotNil(['optionItem', 'category']))
    .map(({ optionItem, qty, category, item }) => ({
      price: (showPricesExTax ? optionItem.priceExTax : optionItem.price) || 0,
      qty,
      type: category.optionType || undefined,
      item,
    }))
  const itemPrice = item.item?.variations
    ? item.item.variations[0].priceCustomerFacing
    : item.item?.price || 0
  const itemPriceExTax = item.item?.variations
    ? item.item.variations[0].priceCustomerFacingExTax
    : item.item?.priceExTax || 0
  const itemTaxRate = item.item?.variations
    ? item.item.variations[0].taxRate
    : item.item?.taxRate || 0
  const itemTaxValue =
    item.item?.variations && item.item.variations[0].taxValue
      ? item.item.variations[0].taxValue
      : item.item?.taxValue || 0

  const itemSubtotals = calculateItemSubtotal(
    {
      itemPrice,
      itemPriceExTax,
      itemTaxRate,
      itemTaxValue,
    },
    item.qty,
    options,
  )
  const total = showPricesExTax ? itemSubtotals.subtotalExTax : itemSubtotals.subtotal
  const itemHasBeenEdited = validateBasketItemUpdate(item) || undefined

  useEffect(() => {
    setQuantity(item.qty)
  }, [item.qty])

  if (item.item) {
    const isGmActive = item.item.variations && item.item.variations[0].isActive
    const servings = (item.item.variations ? item.item.variations[0].servings : 1) || 1
    return (
      <Card
        sx={{
          borderWidth: '1px',
          borderStyle: itemHasBeenEdited ? 'solid' : 'none',
          borderColor: 'error.main',
        }}
      >
        <MuiStack padding={2} paddingTop={1} direction="column">
          <Stack direction="vertical" alignItems="stretch" spacing={4}>
            <Stack justifyContent="space-between" allowWrap={false}>
              <TextSmall style={{ fontWeight: 500, color: theme.colors.text_heading }}>
                {item.item.name}
              </TextSmall>
              {onDeleteClick && isGmActive && (
                <IconButton style={{ margin: 0, padding: 4 }} onClick={onDeleteClick}>
                  <CloseRounded />
                </IconButton>
              )}
            </Stack>
            <Stack justifyContent="space-between">
              <Stack spacing={16}>
                <QuantitySelect
                  min={
                    item.item.variations ? item.item.variations[0].minQty || undefined : undefined
                  }
                  onChange={onChange}
                  quantity={quantity}
                  disabled={!onQuantityChange || !isGmActive}
                  max={2000}
                />
                <Stack spacing={4}>
                  <Icon icon="person" size={12} fill={theme.colors.text_semi_muted} />
                  <TextExtraExtraSmall>Serves {servings * item.qty}</TextExtraExtraSmall>
                </Stack>
              </Stack>

              {itemPrice && (
                <TextExtraSmall style={{ fontWeight: 500, color: theme.colors.text_heading }}>
                  {amountToPriceString(currency, total, true)} (ex {taxAcronym})
                </TextExtraSmall>
              )}
            </Stack>
          </Stack>
          {item.options?.length ? (
            <div>
              <Stack spacing={4} justifyContent="space-between">
                <Stack spacing={4}>
                  <Icon icon="star" size={12} fill={theme.colors.primary} />
                  <TextExtraExtraSmall>Options</TextExtraExtraSmall>
                </Stack>
                <Button
                  size="small"
                  endIcon={showOptions ? <ExpandLessRounded /> : <ExpandMoreRounded />}
                  onClick={toggleShowOptions}
                >
                  {showOptions ? 'Hide' : 'Show'}
                </Button>
              </Stack>
              {showOptions && (
                <Stack direction="vertical" alignItems="start">
                  {item.options
                    .filter(areFieldsNotNil(['category', 'optionItem']))
                    .map((option) => {
                      const optionItemPrice =
                        option.optionItem.priceCustomerFacing || option.optionItem.price
                      const optionItemPriceExTax =
                        option.optionItem.priceCustomerFacingExTax || option.optionItem.priceExTax
                      const optionPriceToShow = showPricesExTax
                        ? optionItemPriceExTax
                        : optionItemPrice
                      const taxCopy = showPricesExTax ? ` ex ${taxAcronym}` : ''
                      return option.item ? (
                        <TextExtraExtraSmall key={option.item._id} style={{ fontWeight: 300 }}>
                          {match(option.category.optionType)
                            .with(MenuItemOptionType.Ticks, null, () => item.qty)
                            .with(MenuItemOptionType.Numbers, () => option.qty)
                            .exhaustive()}{' '}
                          x {option.item.name}{' '}
                          {optionPriceToShow
                            ? `(${amountToPriceString(
                                currency,
                                optionPriceToShow,
                                true,
                              )}${taxCopy})`
                            : ''}
                        </TextExtraExtraSmall>
                      ) : null
                    })}
                </Stack>
              )}
            </div>
          ) : null}
          {item.note && (
            <MuiStack spacing={1} direction="row">
              <Notes fontSize="small" />
              <Typography variant="caption">Notes: {item.note}</Typography>
            </MuiStack>
          )}
        </MuiStack>
      </Card>
    )
  }
  return null
}

export default BasketItemCard
