import React, { useState, useEffect, useCallback } from 'react'
import { useMedia } from 'react-use'
import { mobileMediaQuery } from '@teamfeedr/ui--theme'
import { basketLocationToAddress } from '@teamfeedr/utils--gm-validation'
import DatepickerInput from './datepicker-input'
import Grid, { Item } from '@teamfeedr/ui--grid'
import Modal from '@teamfeedr/ui--modal'
import AddressLookup from './address-lookup'
import TimeSelect from './time-select'
import HeadCountInput from './headcount-input'
import { formatDateDDMMYY } from '@/components/page-specific/gm/helpers/date'
import { ModalWrapper, ButtonWrapper, StyledText } from './styles'
import {
  UserBasketSettings,
  validateUserBasketSettings,
  ValidUserBasketSettings,
} from '../../domain/user-basket-settings'
import {
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Tooltip as MuiTooltip,
} from '@mui/material'
import {
  AccessTime,
  CalendarToday,
  GridViewRounded,
  InfoRounded,
  LocationOn,
  Person,
  ViewListRounded,
} from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { ItemView } from '@/components/page-specific/vp/legacy/shared/components/layouts'
import useAllergenInfoText from '../../helpers/useAllergenInfoText'
import { useAuthState } from '../states/auth'
import {
  MenuVendorLocationWithDeliveryRegionsFragment,
  ServiceType,
  UserBasketSettingsLocationFragment,
  useGetVendorsInRangeLocationsLazyQuery,
} from '@/generated/graphql'
import { getCountryCodeFromName } from '@/helpers/multiRegion/countries'
import { getCutOffLocations, validateAll } from '../../helpers/validation'
import { SPLITS, useFeatureFlag } from '@/helpers/useFeatureFlag'
import useTenant from '@/hooks/useTenant'

type InputBarProps = {
  vendorId?: string
  modalOpen: boolean
  orderSelectedSettings: {
    date?: number
    time?: string
    headCount?: number
    location?: UserBasketSettingsLocationFragment
  }
  disabled: boolean
  modalIsLoading: boolean
  availableVendorLocations?: MenuVendorLocationWithDeliveryRegionsFragment[]
  onValueChange: (values: UserBasketSettings) => void
  toggleItemView: (display: ItemView) => void
  setModalOpen: (open: boolean) => void
  onConfirm: (settings: ValidUserBasketSettings) => void
}

const InputBar = ({
  vendorId,
  orderSelectedSettings,
  modalOpen,
  modalIsLoading,
  availableVendorLocations: availableVendorLocationsProp,
  disabled,
  onValueChange,
  toggleItemView,
  setModalOpen,
  onConfirm,
}: InputBarProps) => {
  const allergenInfoText = useAllergenInfoText()
  const tenant = useTenant()

  const isMobileScreen = useMedia(mobileMediaQuery, false)
  const [closeCalendar, setCloseCalender] = useState(false)

  const authState = useAuthState()

  const [location, setLocation] = useState<UserBasketSettingsLocationFragment | undefined>(
    orderSelectedSettings.location,
  )
  const [date, setDate] = useState<number | undefined>(orderSelectedSettings.date)
  const [time, setTime] = useState<string | undefined>(orderSelectedSettings.time)
  const [headcount, setHeadcount] = useState<number | undefined>(orderSelectedSettings.headCount)
  const [
    getVendorsInRangeLocations,
    { data: inRangeLocationData, loading: loadingInRangeLocationData },
  ] = useGetVendorsInRangeLocationsLazyQuery()
  const inRangeVendorLocations =
    inRangeLocationData?.vendor.inRangeLocations ?? availableVendorLocationsProp ?? []

  const cutOff5pm = useFeatureFlag(SPLITS.GM_CUTOFF_5PM)
  const availableVendorLocations = getCutOffLocations(inRangeVendorLocations, cutOff5pm)

  const newUserState = {
    ...orderSelectedSettings,
    date,
    location,
    headCount: headcount,
    time,
  }
  const basketValidationResult = validateUserBasketSettings(newUserState)

  const { newErrors: errors } = validateAll({
    inRangeVendorLocations: availableVendorLocations,
    userState: newUserState,
    useDeliveryTimes: cutOff5pm,
  })

  const orderCanBeFulfilled =
    Object.keys(errors).length === 0 && basketValidationResult.type === 'success'

  useEffect(() => {
    setLocation(orderSelectedSettings.location)
    setDate(orderSelectedSettings.date)
    setTime(orderSelectedSettings.time)
    setHeadcount(orderSelectedSettings.headCount)
  }, [orderSelectedSettings])

  // Horrible hack to fix datepicker onClickOutside being inside a modal :(
  const handleDatepickerOutsideClick = (target: EventTarget) => {
    const element = target as HTMLElement
    if (typeof element.className !== 'string' || element.className.includes('picker')) return
    setTimeout(() => setCloseCalender(true), 1) // enough time to pop event out of current flow
  }
  useEffect(() => setCloseCalender(false), [closeCalendar])

  const checkInRangeLocations = useCallback(
    async (locationToCheck: UserBasketSettingsLocationFragment) => {
      if (!vendorId) return
      const { latitude, longitude, country } = basketLocationToAddress(locationToCheck)
      const countryCode = getCountryCodeFromName(country) || null
      if (!latitude || !longitude) return
      return getVendorsInRangeLocations({
        variables: {
          vendorId,
          lat: latitude,
          lng: longitude,
          serviceType: ServiceType.Gm,
          countryCode,
          dateTime: null,
        },
      })
    },
    [getVendorsInRangeLocations, vendorId],
  )

  useEffect(() => {
    if (!orderSelectedSettings.location) return
    checkInRangeLocations(orderSelectedSettings.location)
  }, [orderSelectedSettings.location, checkInRangeLocations])

  const handleAddressChange = async (newLocation: UserBasketSettingsLocationFragment) => {
    setLocation(newLocation)
    const result = await checkInRangeLocations(newLocation)
    if (result?.data && result.data.vendor.inRangeLocations.length > 0)
      onValueChange({ location: newLocation })
  }

  const handleDateChange = (newDate: number) => {
    setDate(newDate)
    const orderFulfilmentResult = validateAll({
      inRangeVendorLocations: availableVendorLocations,
      userState: { ...newUserState, date: newDate },
      useDeliveryTimes: cutOff5pm,
    })
    if (!orderFulfilmentResult.newErrors.date) onValueChange({ date: newDate })
  }

  const handleTimeChange = (newTime: string) => {
    setTime(newTime)
    const orderFulfilmentResult = validateAll({
      inRangeVendorLocations: availableVendorLocations,
      userState: { ...newUserState, time: newTime },
      useDeliveryTimes: cutOff5pm,
    })
    if (!orderFulfilmentResult.newErrors.time) onValueChange({ time: newTime })
  }

  const handleHeadCountChange = (newHeadcount: number) => {
    setHeadcount(newHeadcount)
    const orderFulfilmentResult = validateAll({
      inRangeVendorLocations: availableVendorLocations,
      userState: { ...newUserState, headCount: newHeadcount },
      useDeliveryTimes: cutOff5pm,
    })
    if (!orderFulfilmentResult.newErrors.time) onValueChange({ headCount: newHeadcount })
  }

  const handleConfirmModal = () => {
    if (!orderCanBeFulfilled) return
    onConfirm(basketValidationResult.value)
  }

  const openModal = () => {
    if (disabled) return
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  return (
    <>
      <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1} alignItems="center">
        <Typography width={{ xs: '100%', lg: 'fit-content' }} color="text.secondary">
          Ordering for
        </Typography>
        <TextField
          sx={{ width: { xs: '100%', sm: '170px', md: '200px' }, flexGrow: { xs: 1, md: 'unset' } }}
          onClick={openModal}
          size="small"
          placeholder="Deliver to"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocationOn color="primary" />
              </InputAdornment>
            ),
          }}
          disabled={disabled}
          value={
            (orderSelectedSettings.location &&
              (basketLocationToAddress(orderSelectedSettings.location).postcode ||
                basketLocationToAddress(orderSelectedSettings.location).street)) ||
            undefined
          }
        />
        {!isMobileScreen ? (
          <>
            <TextField
              sx={{ width: { xs: '150px', md: '170px' }, flexGrow: { xs: 1, md: 'unset' } }}
              onClick={openModal}
              size="small"
              placeholder="Date"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarToday color="primary" />
                  </InputAdornment>
                ),
              }}
              disabled={disabled}
              value={formatDateDDMMYY(orderSelectedSettings.date || undefined)}
            />
            <TextField
              sx={{ width: '175px' }}
              onClick={openModal}
              size="small"
              placeholder="Time"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccessTime color="primary" />
                  </InputAdornment>
                ),
              }}
              disabled={disabled}
              value={orderSelectedSettings.time || ''}
            />
            <TextField
              sx={{ width: '110px' }}
              onClick={openModal}
              size="small"
              placeholder="0"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Person color="primary" />
                  </InputAdornment>
                ),
              }}
              disabled={disabled}
              value={orderSelectedSettings.time ? orderSelectedSettings.headCount : ''}
            />
            <Stack
              flexWrap="wrap"
              flexGrow={1}
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <IconButton size="small" onClick={() => toggleItemView(ItemView.Grid)}>
                <GridViewRounded color="primary" fontSize="large" />
              </IconButton>
              <IconButton size="small" onClick={() => toggleItemView(ItemView.List)}>
                <ViewListRounded color="primary" fontSize="large" />
              </IconButton>
              {tenant.features?.cateringAllergenTooltip?.visibility !== false && (
                <MuiTooltip title={allergenInfoText}>
                  <Button size="small" variant="text" startIcon={<InfoRounded />}>
                    Allergens
                  </Button>
                </MuiTooltip>
              )}
            </Stack>
          </>
        ) : null}
      </Stack>

      {modalOpen && (
        <ModalWrapper onClick={(e) => handleDatepickerOutsideClick(e.target)}>
          <Modal.Wrapper
            centerHeader
            header="Confirm your details"
            body={
              <>
                <Modal.Section padding={{ x: 0, y: 0 }}>
                  <StyledText>We&apos;ll make sure your order can be fulfilled</StyledText>
                </Modal.Section>
                <Modal.Section padding={{ y: 24 }}>
                  <AddressLookup
                    error={loadingInRangeLocationData ? '' : errors.address}
                    onAddressChanged={(newLocation) => {
                      handleAddressChange(newLocation)
                    }}
                    selectedLocation={location}
                    availableAccountLocations={
                      authState.type === 'authenticated'
                        ? authState.user.account?.locations ?? []
                        : []
                    }
                  />
                  <Grid margin={false}>
                    <Item size={4} m={3} s={3} xs={4}>
                      <DatepickerInput
                        error={errors.date}
                        showErrors={Boolean(errors.date && !errors.headCount)}
                        disabled={!location}
                        availableVendorLocations={availableVendorLocations}
                        dateValue={date}
                        onChange={(newDate) => {
                          handleDateChange(newDate)
                        }}
                        forceClose={closeCalendar}
                        showChevron={true}
                      />
                    </Item>
                    <Item size={4} m={3} s={3} xs={4}>
                      <TimeSelect
                        error={errors.time}
                        onTimeChange={(newTime) => {
                          handleTimeChange(newTime)
                        }}
                        selectedDate={date}
                        selectedTime={time}
                        availableVendorLocations={inRangeVendorLocations}
                      />
                    </Item>
                    <Item size={2} xs={4}>
                      <HeadCountInput
                        onChange={(newHeadcount) => {
                          handleHeadCountChange(newHeadcount)
                        }}
                        headCount={headcount}
                        error={time && date ? errors.headCount : ''}
                        maxHeadCount={
                          availableVendorLocations.reduce(
                            (acc, location) => Math.max(location.details.gmMaxOrder || 0, acc),
                            0,
                          ) || Infinity
                        }
                        unusable={!time}
                        disabled={!time}
                      />
                    </Item>
                  </Grid>
                  <ButtonWrapper>
                    <LoadingButton
                      variant="contained"
                      disabled={!orderCanBeFulfilled}
                      fullWidth
                      loading={modalIsLoading}
                      onClick={handleConfirmModal}
                    >
                      Confirm
                    </LoadingButton>
                  </ButtonWrapper>
                </Modal.Section>
              </>
            }
            onClose={closeModal}
          />
        </ModalWrapper>
      )}
    </>
  )
}

export default InputBar
