import { AddCircle, RemoveCircle } from '@mui/icons-material'
import { IconButton, Stack, Typography } from '@mui/material'
import React from 'react'

type Props = {
  quantity: number
  size?: 'small' | 'medium' | 'large'
  onChange?: (newValue: number) => void
  min?: number
  max?: number
  disabled?: boolean
}

const QuantitySelect: React.FC<Props> = ({
  quantity,
  size = 'medium',
  min = 0,
  max,
  disabled,
  onChange = () => {},
}) => {
  return (
    <Stack alignItems="center" direction="row">
      <IconButton
        edge="start"
        disabled={disabled || quantity === min}
        size={size}
        onClick={() => onChange(quantity - 1)}
      >
        <RemoveCircle fontSize={size} />
      </IconButton>
      <Typography fontSize={size} textAlign="center" minWidth="1.5em" variant="body2">
        {quantity}
      </Typography>
      <IconButton
        disabled={disabled || Boolean(max && quantity === max)}
        size={size}
        onClick={() => onChange(quantity + 1)}
      >
        <AddCircle fontSize={size} />
      </IconButton>
    </Stack>
  )
}

export default QuantitySelect
