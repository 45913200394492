import styled from 'styled-components'
import { Text } from '@teamfeedr/ui--typography'
import React from 'react'
import RedesignedMenuItemCardAug2024 from '../menu-item-card/redesignAug2024'
import { MenuCategory } from '../../domain/menu-category'
import { MenuVendor } from '../../domain/menu-vendor'
import { AddItemPayload } from '../states/menu-page/add-item-mutation'
import { ItemView } from '@/components/page-specific/vp/legacy/shared/components/layouts'
import LoadingIndicator from '@/components/LoadingIndicator'
import { Grid as MuiGrid, useMediaQuery, useTheme } from '@mui/material'

const CategorySection = styled.div`
  margin-bottom: 45px;
`

const CategoryTitle = styled.h2`
  font-weight: ${(p) => p.theme.fontWeights.medium};
  font-size: ${(p) => p.theme.fontSizes.base};
  line-height: 22px;
  color: ${(p) => p.theme.colors.primary};
  text-transform: uppercase;
  margin: 0 0 ${(p) => p.theme.spacings[3]}px;
`

const CategoryDivider = styled.div`
  background: linear-gradient(
    to right,
    ${(p) => p.theme.colors.primary} 0%,
    rgba(79, 191, 112, 0.0001) 98.64%
  );
  mix-blend-mode: normal;
  opacity: 0.4;
  height: 2px;
  width: 50%;
  margin: 16px 0;
`

const MassiveBox = styled.div`
  margin: 100px 0;
  text-align: center;
`

export type Props = {
  display: ItemView
  vendor: MenuVendor
  categories: MenuCategory[]
  onAddItemToBasketClick: (item: AddItemPayload) => void
  basketIsLoading: boolean
  closeModalWithoutLoading: boolean
  showPricesExTax: boolean
  taxAcronym: string
  loadingMore: boolean
}

export const MenuItemsSection: React.FC<Props> = ({
  display,
  vendor,
  categories,
  onAddItemToBasketClick,
  basketIsLoading,
  closeModalWithoutLoading,
  showPricesExTax,
  taxAcronym,
  loadingMore,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const cardSize = display === ItemView.Grid ? 6 : 12
  const numberOfCardsInColumn = !isMobile && display === ItemView.Grid ? 2 : 1
  return categories.length ? (
    <>
      {categories.map((category, i) => (
        <CategorySection key={i}>
          <CategoryTitle>{category.name}</CategoryTitle>
          <CategoryDivider />
          <MuiGrid container spacing={1}>
            {category.items.map((item) => (
              <MuiGrid item md={cardSize} sm={cardSize} xs={12} key={`${i}-${item._id}`}>
                <RedesignedMenuItemCardAug2024
                  numberInCol={numberOfCardsInColumn}
                  taxAcronym={taxAcronym}
                  showPricesExTax={showPricesExTax}
                  item={item}
                  vendor={vendor}
                  onAddItemToBasketClick={onAddItemToBasketClick}
                  basketIsLoading={basketIsLoading}
                  closeModalWithoutLoading={closeModalWithoutLoading}
                />
              </MuiGrid>
            ))}
          </MuiGrid>
        </CategorySection>
      ))}
      {loadingMore ? <LoadingIndicator size="small" /> : null}
    </>
  ) : (
    <MassiveBox>
      <Text>Oops, looks like no menu items match this filter selection.</Text>
      <Text>Remove the filter/s to view all menu items.</Text>
    </MassiveBox>
  )
}
