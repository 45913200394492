import { useLanguage, useRouter } from '@/components/Link'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { match } from 'ts-pattern'
import { z } from 'zod'
import { format } from 'date-fns'
import { useMedia } from 'react-use'
import { mobileMediaQuery } from '@teamfeedr/ui--theme'
import { populateFieldsWithTranslations } from '@teamfeedr/utils--translations'
import { createMenuAvailability, MenuAvailability } from '../../domain/menu-availability'
import { SelectedMenuFilterCategory } from '../../domain/menu-category-filter'
import { MenuItemFilterSections } from '../../domain/menu-item-filter'
import { MenuVendor, validateMenuVendor } from '../../domain/menu-vendor'
import { isSameLocation } from '@/components/page-specific/gm/helpers/addresses'
import { useAppError } from '../../utils/errors'
import { BasketErrors } from '../../types/enums/Errors.enum'
import { useAuthState } from './auth'
import { useBasketState } from './basket'
import { useAppState } from './app'
import { getLocalBasketIdForVendor, setLocalBasketIdForVendor } from './local-basket'
import {
  BasketFragment,
  GetVendorAvailabilityQuery,
  GetVendorQuery,
  MenuVendorLocationWithDeliveryRegionsFragment,
  ServiceType,
  useGetVendorAvailabilityLazyQuery,
  useGetVendorsInRangeLocationsLazyQuery,
} from '@/generated/graphql'
import { getCountryCodeFromName } from '@/helpers/multiRegion/countries'
import {
  UserBasketSettings,
  validateUserBasketSettings,
  ValidUserBasketSettings,
} from '../../domain/user-basket-settings'
import { UpdateItemQty, useUpdateItemQtyMutation } from './menu-page/update-item-qty-mutation'
import { RemoveItemFromBasket, useRemoveItemMutation } from './menu-page/remove-item-mutation'
import { UpdateItemInBasket, useUpdateItemMutation } from './menu-page/update-item-mutation'
import { AddItemPayload, useAddItemMutation } from './menu-page/add-item-mutation'
import { useGetBasketQuery } from './menu-page/get-basket-query'
import { useCreateBasketMutation } from './menu-page/create-basket-mutation'
import { useUpdateBasketMutation } from './menu-page/update-basket-mutation'
import { useUserBasketSettingsState } from './useUserBasketSettingsState'
import { populateBasketTranslationFields } from '../../helpers/translations'
import { useFeatureFlag, SPLITS } from '@/helpers/useFeatureFlag'
import { taxAcronym } from '@/helpers/vatRegions'
import { basketLocationToAddress } from '@teamfeedr/utils--gm-validation'
import useTenant from '@/hooks/useTenant'
import { useAdminGlobalState } from '@/components/page-specific/admin/useAdminGlobalState'
import { ItemView } from '@/components/page-specific/vp/legacy/shared/components/layouts'
import moment from 'moment'
import { userStateTimeToHoursAndMins } from '../../helpers/date'
import useGmFiltersStore from '../../helpers/gmFiltersStore'

type AddItemToBasket = (payload: AddItemPayload) => void
type HandleUserBasketSettingsConfirm = (settings: ValidUserBasketSettings) => void
type ClearBasket = () => void

export type LoadedMenu = {
  type: 'loaded'
  availability: MenuAvailability
  hasMore: boolean
  loadMore: () => void
  loadingMore: boolean
  draftModalIsOpen: boolean
  setDraftModalIsOpen: (isOpen: boolean) => void
  keepDraft: () => void
  basket: BasketFragment | null
  basketIsUpdating: boolean
  addItemToBasket: AddItemToBasket
  updateItemInBasket: UpdateItemInBasket
  updateItemQty: UpdateItemQty
  removeItemFromBasket: RemoveItemFromBasket
  clearBasket: ClearBasket
  handleUserBasketSettingsConfirm: HandleUserBasketSettingsConfirm

  basketIsOpen: boolean
  basketClosedByUser: boolean
  handleBasketClosedByUser: () => void
  toggleBasketIsOpen: (display: boolean) => void
  itemView: ItemView
  toggleItemView: (display: ItemView) => void
}
type Menu = { type: 'loading' } | LoadedMenu
export type OrderDetails = UserBasketSettings & {
  orderDetailsModalOpen: boolean
  setOrderDetailsModalOpen: (isOpen: boolean) => void
}

type BasketErrorType = keyof typeof BasketErrors

type PageState = {
  type: 'loaded'
  vendor: MenuVendor
  selectedVendorLocation?: MenuVendorLocationWithDeliveryRegionsFragment
  menu: Menu
  showPricesExTax: boolean
  taxAcronym: string
  orderDetailsState: OrderDetails
  basketError: BasketErrorType | null
  setBasketError: (error: BasketErrorType) => void
  minOrderValue: number | null
  minOrderValueExTax: number | null
}

export const routerQuerySchema = z.object({
  permalink: z.string().min(1),
  basketId: z.string().min(24).optional(),
  showOrderDetails: z.string().optional(),
  itemName: z.string().optional(),
  openBasket: z.string().optional(),
})

export const useMenuPageState = ({
  vendorDetails,
  disableBasketFetching = false,
  itemData,
  useServerSideItems,
}: {
  vendorDetails: GetVendorQuery['vendor']
  itemData?: GetVendorAvailabilityQuery
  disableBasketFetching?: boolean
  useServerSideItems?: boolean
}): PageState => {
  const { throwAppError } = useAppError()
  const router = useRouter()
  const {
    userState,
    selectedVendorLocation,
    setSelectedVendorLocation,
    setInRangeVendorLocations,
    setBasketIsFeedrAdminApproved,
    validate,
    deliveryFee,
    updateDeliveryFee,
    minOrderValue,
    updateMinOrderValue,
    minOrderValueExTax,
  } = useAppState()
  const authState = useAuthState()
  const tenant = useTenant()
  const { selectedCountry: countryCode } = useAdminGlobalState()
  const showPricesExTax = useFeatureFlag(SPLITS.PRICES_EX_TAX_GM, {
    tenantId: tenant.id,
    countryCode: countryCode,
  })
  const globalBasketState = useBasketState()
  const userBasketSettingsState = useUserBasketSettingsState()
  const isMobileScreen = useMedia(mobileMediaQuery, false)

  const userBasketSettings = userBasketSettingsState.settings.value

  const {
    permalink,
    basketId: sharedBasketId,
    showOrderDetails,
    openBasket,
  } = routerQuerySchema.parse(router.query)

  const [draftModalIsOpen, setDraftModalIsOpen] = useState(false)
  const [basketClosedByUser, setBasketClosedByUser] = useState(false)
  const [itemView, setItemView] = useState<ItemView>(ItemView.Grid)
  const [basketId, setBasketId] = useState(sharedBasketId)
  const [basketCleared, setBasketCleared] = useState(false)
  const [basketError, setBasketError] = useState<BasketErrorType | null>(null)
  const { itemName, selectedCategory, goals, allergens, dietaries } = useGmFiltersStore()
  const [userBasketSettingsModal, setUserBasketSettingsModal] = useState<
    | { type: 'displayed'; basketToCreateOnConfirm?: { firstItem: AddItemPayload } }
    | { type: 'hidden' }
  >({ type: 'hidden' })
  const [getVendorsInRangeLocations] = useGetVendorsInRangeLocationsLazyQuery()

  useEffect(() => {
    if (showOrderDetails) {
      setUserBasketSettingsModal({
        type: 'displayed',
      })
    }
  }, [showOrderDetails])

  useEffect(() => {
    setBasketId(sharedBasketId)
  }, [sharedBasketId])

  const language = useLanguage()

  const rawVendor = vendorDetails
  const vendorTranslated = rawVendor
    ? populateFieldsWithTranslations(rawVendor, language)
    : rawVendor

  const vendorValidation = useMemo(
    () => (vendorTranslated ? validateMenuVendor(vendorTranslated) : ({ type: 'idle' } as const)),
    [rawVendor],
  )

  const [runGetAvailability, getAvailabilityResult] = useGetVendorAvailabilityLazyQuery()
  const rawAvailability =
    useServerSideItems && itemData
      ? itemData.availability
      : getAvailabilityResult.data?.availability ?? getAvailabilityResult.previousData?.availability

  const availability = useMemo(() => {
    return vendorValidation.type === 'success' && rawAvailability
      ? createMenuAvailability({
          availability: { ...rawAvailability, itemsPagedv2: rawAvailability?.itemsPagedv2 || [] },
          vendorCategories: vendorValidation.vendor.categories,
          selectedCategory,
          useServerSideItems,
          filters: {
            itemName,
            goals,
            dietaries,
            allergens,
          },
        })
      : null
  }, [rawAvailability, selectedCategory, itemName, goals, dietaries, allergens])

  const [loadingMore, setLoadingMore] = useState(false)

  const loadMore = useCallback(async () => {
    if (useServerSideItems) return
    setLoadingMore(true)
    await getAvailabilityResult.fetchMore({
      variables: {
        offset: availability?.items.length,
        limit: 20,
      },
    })
    setLoadingMore(false)
  }, [getAvailabilityResult, availability?.items.length])

  const dateTimeFromUserState = userState.date
    ? {
        date: moment(userState.date).date(),
        month: moment(userState.date).month(),
        year: moment(userState.date).year(),
        hour: userState.time ? Number(userStateTimeToHoursAndMins(userState.time).hours) : 0,
        minute: userState.time ? Number(userStateTimeToHoursAndMins(userState.time).minutes) : 0,
      }
    : null

  const getInRangeLocations = async (vendorId: string) => {
    if (!userState?.location) return
    const { latitude, longitude, country } = basketLocationToAddress(userState.location)
    if (!latitude || !longitude) return
    const countryCode = getCountryCodeFromName(country) || country || null
    const dateTime = dateTimeFromUserState
      ? {
          ...dateTimeFromUserState,
          timezone: 'UTC',
        }
      : null
    const { data } = await getVendorsInRangeLocations({
      variables: {
        vendorId,
        lat: latitude,
        lng: longitude,
        serviceType: ServiceType.Gm,
        countryCode,
        dateTime,
      },
    })
    if (data) {
      setInRangeVendorLocations(data.vendor.inRangeLocations)
      if (data.vendor.inRangeLocations.length) {
        const closestLocation = data.vendor.inRangeLocations[0]
        setSelectedVendorLocation(closestLocation)
        updateDeliveryFee(closestLocation)
        updateMinOrderValue(closestLocation)
      }
    }
  }

  const constructFiltersForQuery = (
    filters: MenuItemFilterSections,
    category: SelectedMenuFilterCategory,
  ) => {
    const selectedDietaries = [
      ...filters.goals.map((goal) => goal.filterInputValue),
      ...filters.dietaries.reduce<string[]>((dietaries, dietary) => {
        if (dietary.filterArray !== 'dietaries') return dietaries
        return [...dietaries, dietary.filterInputValue]
      }, []),
    ]
    const selectedAllergens = [
      ...filters.allergens.map((allergen) => allergen.value),
      ...filters.dietaries.reduce<string[]>((dietaries, dietary) => {
        if (dietary.filterArray !== 'allergens') return dietaries
        return [...dietaries, dietary.filterInputValue]
      }, []),
    ]
    const categoryId = Number(category.id)
    return {
      allergens: selectedAllergens,
      dietaries: selectedDietaries,
      ...(!Number.isNaN(categoryId) && { categoryId: [categoryId] }),
      ...(category.id === 'hot_dishes' && { isHot: true }),
      ...(category.id === 'cold_dishes' && { isHot: false }),
      name: itemName,
      ...(dateTimeFromUserState && {
        deliveryDate: { ...dateTimeFromUserState, timezone: 'Europe/London' },
      }),
    }
  }

  useEffect(() => {
    if (useServerSideItems) return
    if (vendorValidation.type !== 'success') return
    const sortedCategoryIds = [...vendorValidation.vendor.categories]
      .sort((a, b) => {
        if (a.position === null) return 1
        if (b.position === null) return -1
        return a.position - b.position
      })
      .map((category) => Number(category.id))

    const constructedFilters = constructFiltersForQuery(
      {
        goals,
        allergens,
        dietaries,
      },
      selectedCategory,
    )
    void runGetAvailability({
      variables: {
        vendorId: vendorValidation.vendor.id,
        filters: constructedFilters,
        sorting: { categorySortPriority: sortedCategoryIds },
        offset: 0,
        limit: 20,
      },
    })
  }, [
    vendorValidation,
    goals,
    allergens,
    dietaries,
    selectedCategory,
    userState.location,
    userState.date,
    userState.time,
  ])

  useEffect(() => {
    if (vendorValidation.type !== 'success') return
    void getInRangeLocations(vendorValidation.vendor.id)
  }, [userState.location, userState.date, userState.time])

  useEffect(() => {
    if (!availability || vendorValidation.type !== 'success' || sharedBasketId || basketCleared)
      return
    const basketId =
      availability?.myLastBasket?.id || getLocalBasketIdForVendor(vendorValidation.vendor.id)
    if (basketId) setBasketId(basketId)
  }, [availability, vendorValidation])

  const handleItemAddedToBasket = () => {
    setUserBasketSettingsModal({ type: 'hidden' })
    globalBasketState.declareNewItemWasAdded()
    if (!isMobileScreen && !basketClosedByUser) globalBasketState.toggleIsOpen(true)
  }

  const setOrderDetailsStateFromBasket = (basket: BasketFragment) => {
    userBasketSettingsState.settings.set({
      location: basket.settings.location || undefined,
      date: new Date(basket.settings.deliveryDate).getTime(),
      time: basket.settings.deliveryTimeSlot,
      headCount: basket.settings.headCount,
    })
    setBasketIsFeedrAdminApproved(Boolean(basket.settings.feedrAdminApproved))
  }

  const createBasketMutation = useCreateBasketMutation()
  const updateBasketMutation = useUpdateBasketMutation()
  const getBasketQuery = useGetBasketQuery()

  const fetchedBasket = getBasketQuery.basket
  const basket = useMemo(() => {
    return fetchedBasket && basketId === fetchedBasket.id
      ? populateBasketTranslationFields(fetchedBasket, language)
      : null
  }, [basketId, fetchedBasket, language])

  const previousDetailsDifferentToBasket =
    basket &&
    ((userBasketSettings.date &&
      format(new Date(userBasketSettings.date), 'yyyy-MM-dd') !== basket.settings.deliveryDate) ||
      (userBasketSettings.time && userBasketSettings.time !== basket.settings.deliveryTimeSlot) ||
      (userBasketSettings.location &&
        basket.settings.location &&
        !isSameLocation(userBasketSettings.location, basket.settings.location)))

  useEffect(() => {
    if (!basketId || disableBasketFetching) return
    void getBasketQuery.run(basketId)
  }, [basketId, disableBasketFetching])

  const keepDraft = () => {
    if (!basket) return
    setOrderDetailsStateFromBasket(basket)
    setDraftModalIsOpen(false)
  }

  const addItemMutation = useAddItemMutation(deliveryFee)
  const updateItemMutation = useUpdateItemMutation(deliveryFee)
  const updateItemQtyMutation = useUpdateItemQtyMutation(deliveryFee)
  const removeItemMutation = useRemoveItemMutation(deliveryFee)

  useEffect(() => {
    if (vendorValidation.type !== 'success' || !availability || (basketId && !basket)) return
    const { currency } = vendorValidation.vendor
    globalBasketState.setBasket(
      basket
        ? {
            itemCount: basket.itemCount,
            subtotal: basket.subtotalBreakdown.subtotalExTax,
            currency,
          }
        : { itemCount: 0, subtotal: 0, currency },
    )
  }, [availability, basket])

  useEffect(() => {
    if (openBasket && !basketClosedByUser) globalBasketState.toggleIsOpen(true)
  })

  useEffect(() => {
    if (!basket) return

    if (sharedBasketId && !basketClosedByUser) {
      globalBasketState.toggleIsOpen(true)
    }

    const orderDetailsModalOpen = match(userBasketSettingsModal.type)
      .with('displayed', () => true)
      .with('hidden', () => false)
      .exhaustive()
    if (orderDetailsModalOpen) return

    if (previousDetailsDifferentToBasket && !sharedBasketId) {
      setDraftModalIsOpen(true)
    } else {
      setOrderDetailsStateFromBasket(basket)
      void validate()
    }
  }, [basket])

  useEffect(() => {
    router.events.on('routeChangeStart', globalBasketState.clear)
    return () => router.events.off('routeChangeStart', globalBasketState.clear)
  }, [])

  useEffect(() => {
    if (getBasketQuery.error) setBasketError('COULD_NOT_FETCH_BASKET')
    if (createBasketMutation.error) setBasketError('COULD_NOT_CREATE_BASKET')
    if (updateItemQtyMutation.error) setBasketError('COULD_NOT_UPDATE_BASKET')
    if (addItemMutation.error) setBasketError('COULD_NOT_ADD_ITEM_TO_BASKET')
    if (updateItemMutation.error) setBasketError('COULD_NOT_UPDATE_ITEM_IN_BASKET')
    if (updateItemQtyMutation.error) setBasketError('COULD_NOT_UPDATE_ITEM_QTY_IN_BASKET')
    if (removeItemMutation.error) setBasketError('COULD_NOT_REMOVE_ITEM_FROM_BASKET')
  }, [
    getBasketQuery,
    createBasketMutation,
    updateBasketMutation,
    addItemMutation,
    updateItemMutation,
    updateItemQtyMutation,
    removeItemMutation,
  ])

  const account = match(authState)
    .with({ type: 'authenticated' }, ({ user }) => user.account)
    .with({ type: 'unauthenticated' }, () => null)
    .with({ type: 'loading' }, () => null)
    .exhaustive()

  if (vendorValidation.type === 'idle')
    return throwAppError({
      type: 'COULD_NOT_FETCH_VENDOR',
      permalink: permalink,
      cause: 'Could not load vendor details',
    })
  if (vendorValidation.type === 'error') return throwAppError(vendorValidation.error)
  const { vendor } = vendorValidation

  const initialPageState: PageState = {
    type: 'loaded',
    vendor,
    selectedVendorLocation,
    basketError: null,
    setBasketError: () => null,
    menu: { type: 'loading' },
    showPricesExTax: false,
    taxAcronym: 'tax',
    orderDetailsState: {
      ...userBasketSettings,
      orderDetailsModalOpen: match(userBasketSettingsModal.type)
        .with('displayed', () => true)
        .with('hidden', () => false)
        .exhaustive(),
      setOrderDetailsModalOpen: (open: boolean) =>
        setUserBasketSettingsModal({ type: open ? 'displayed' : 'hidden' }),
    },
    minOrderValue: null,
    minOrderValueExTax: null,
  }
  if (
    !useServerSideItems &&
    (!getAvailabilityResult.called ||
      (getAvailabilityResult.loading && !getAvailabilityResult?.previousData))
  ) {
    return initialPageState
  }

  if (!availability)
    return throwAppError({
      type: 'COULD_NOT_FETCH_AVAILABILITY_FOR_VENDOR',
      vendorId: vendor.id,
      cause: getAvailabilityResult.error,
    })

  const createBasket = (itemPayload: AddItemPayload, userBasketSettings: ValidUserBasketSettings) =>
    createBasketMutation.run({
      vendor,
      selectedVendorLocation: selectedVendorLocation || null,
      availability,
      itemPayload,
      account,
      userBasketSettings,
      onCompleted: ({ basket }) => {
        setBasketId(basket.id)
        handleItemAddedToBasket()
        if (authState.type === 'unauthenticated') setLocalBasketIdForVendor(vendor.id, basket.id)
      },
    })

  const addItemToBasket: AddItemToBasket = (payload) => {
    match(validateUserBasketSettings(userBasketSettings))
      .with({ type: 'success' }, ({ value: validUserBasketSettings }) => {
        if (basket) {
          addItemMutation.run(basket, payload)
          handleItemAddedToBasket()
          return
        }
        const validated = validate()
        if (validated) createBasket(payload, validUserBasketSettings)
        else {
          setUserBasketSettingsModal({
            type: 'displayed',
            basketToCreateOnConfirm: { firstItem: payload },
          })
        }
      })
      .with({ type: 'error' }, () => {
        setUserBasketSettingsModal({
          type: 'displayed',
          basketToCreateOnConfirm: { firstItem: payload },
        })
      })
      .exhaustive()
  }

  const handleUserBasketSettingsConfirm: HandleUserBasketSettingsConfirm = (userBasketSettings) => {
    if (
      userBasketSettingsModal.type === 'displayed' &&
      userBasketSettingsModal.basketToCreateOnConfirm
    )
      createBasket(userBasketSettingsModal.basketToCreateOnConfirm.firstItem, userBasketSettings)
    else if (basket && selectedVendorLocation)
      void updateBasketMutation.run({
        basket,
        vendor,
        selectedVendorLocation,
        account,
        userBasketSettings,
        onCompleted: () => setUserBasketSettingsModal({ type: 'hidden' }),
      })
    else setUserBasketSettingsModal({ type: 'hidden' })
  }

  const handleBasketClosedByUser = () => {
    globalBasketState.toggleIsOpen(false)
    setBasketClosedByUser(true)
  }

  return {
    ...initialPageState,
    taxAcronym: taxAcronym(selectedVendorLocation?.country || ''),
    showPricesExTax,
    basketError,
    setBasketError,
    menu: {
      type: 'loaded',

      availability,
      hasMore: Boolean(getAvailabilityResult.data?.availability?.itemsPagedv2.pageInfo.hasMore),
      loadMore,
      loadingMore,
      draftModalIsOpen,
      setDraftModalIsOpen,
      keepDraft,

      basket,
      basketIsUpdating: [
        createBasketMutation,
        updateBasketMutation,
        addItemMutation,
        updateItemMutation,
        updateItemQtyMutation,
        removeItemMutation,
      ].some((r) => r.loading),
      addItemToBasket,
      updateItemInBasket: updateItemMutation.run,
      updateItemQty: updateItemQtyMutation.run,
      removeItemFromBasket: removeItemMutation.run,
      handleUserBasketSettingsConfirm,
      clearBasket: () => {
        setBasketId(undefined)
        setBasketCleared(true)
        setBasketClosedByUser(false)
        globalBasketState.clear()
      },
      basketIsOpen: globalBasketState.isOpen,
      basketClosedByUser,
      handleBasketClosedByUser,
      toggleBasketIsOpen: globalBasketState.toggleIsOpen,
      itemView,
      toggleItemView: setItemView,
    },
    minOrderValue,
    minOrderValueExTax,
  }
}
